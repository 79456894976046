<script setup>
  import FroalaEditor from "froala-editor";
  import { onMounted, ref, watch, reactive } from "vue";
  import { v4 as uuidv4 } from "uuid";
  import GenexMathView from "@/components/froala/GenexMathView.vue";
  import { useBuefy } from "@/compositions/utils/useBuefy";
  import { useVueApp } from "@/compositions/useVueApp";
  import MathLiveKeyboard from "@/components/froala/MathLiveKeyboard.vue";
  import Media from "@/models/sis/Media";

  const emit = defineEmits(["input"]);
  const $buefy = useBuefy();
  const app = useVueApp();
  const uuid = uuidv4();
  const editor = ref(null);
  let loading = ref(false);

  const props = defineProps({
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hideMathView: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: "Modules\\AssessmentQuestion\\Entities\\AssessmentQuestion",
    },
    modelId: {
      type: Number,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Write Something Here...",
    },
    enableCharacterLimit: {
      type: Boolean,
      default: false,
    },
    maxCharacters: {
      type: Number,
      default: 9999,
    },
    mediaPath: {
      type: String,
      default: "wysiwyg-inline/",
    },
    visibility: {
      type: Number,
      default: 1,
    },
    isCentral: {
      trype: Boolean,
      default: false,
    },
    isFooter: {
      trype: Boolean,
      default: false,
    },
  });

  const imageCount = reactive({
    count: 0,
    max: 1,
  });

  const valueCopy = ref(props.value);
  watch(
    () => props.value,
    value => {
      valueCopy.value = value;
    },
  );
  watch(valueCopy, value => {
    emit("input", value);
  });

  const checkImageUpload = images => {
    if (
      (props.isFooter && imageCount.count >= imageCount.max) ||
      (props.isFooter && valueCopy.value.includes("<img"))
    ) {
      $buefy.toast.open({
        message: "Maximum of 1 image allowed in the footer.",
        type: "is-danger",
        duration: 3000,
      });
      return false;
    }
    const editorInstance = editor.value.getEditor();
    editorInstance.popups.hideAll(); //close all editor's popups
    loading.value = true;
    if (images.length) {
      const img = images[0];
      let formData = new FormData();
      formData.append("file", img);
      formData.append("visibility", props.visibility);

      if (!props.modelId) {
        formData.append("path", props.mediaPath);
        Media.StoreByPath(formData)
          .then(({ entities: { media } }) => {
            let url = props.visibility === 1 ? media[0].url : media[0].temporary_url;
            editorInstance.image.insert(url, null, null, editorInstance.image.get());
            // Stop default upload chain.
            loading.value = false;
            return false;
          })
          .catch(() => {
            $buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
            });
            loading.value = false;
          });
      } else {
        Media.Store(
          {
            model: props.model,
            model_id: props.modelId,
          },
          formData,
          props.isCentral,
        )
          .then(({ entities: { media } }) => {
            editorInstance.image.insert(media[0].url, null, null, editorInstance.image.get());
            // Stop default upload chain.
            loading.value = false;
            return false;
          })
          .catch(() => {
            $buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
            });
            loading.value = false;
          });
      }
    }
  };
  const showMathLiveKeyboard = () => {
    // Save the current selection (cursor position)
    const savedSelection = editor.value.getEditor().selection.save();

    $buefy.modal.open({
      parent: app,
      component: MathLiveKeyboard,
      hasModalCard: true,
      trapFocus: true,
      canCancel: ["x"],
      events: {
        add: async latex => {
          // Restore the selection (cursor position)
          if (savedSelection) {
            editor.value.getEditor().selection.restore();
          }

          // Insert latex at cursor position
          editor.value.getEditor().html.insert(latex);
          valueCopy.value = editor.value.getEditor().html.get();
        },
      },
    });
  };

  const plugins = ref([
    "image",
    "align",
    "draggable",
    "fontFamily",
    "fontSize",
    "lists",
    "imageTUI",
    "charCounter",
    "url",
    "lineBreaker",
    "lineHeight",
    "help",
    "fullscreen",
    "link",
  ]);

  if (
    (props.model === "packages" && props.modelId) ||
    props.model === "subject" ||
    props.model === "report_user_comments" ||
    props.model === "report_intro_paragraph"
  ) {
    plugins.value = [
      "align",
      "draggable",
      "fontFamily",
      "fontSize",
      "lists",
      "imageTUI",
      "charCounter",
      "url",
      "lineBreaker",
      "lineHeight",
      "help",
      "fullscreen",
      "link",
    ];
  }
  onMounted(() => {
    FroalaEditor.DefineIconTemplate("material_design", '<i class="mdi mdi-[NAME]" style="font-size: 21px;"></i>');
    FroalaEditor.DefineIcon("functionIcon", { NAME: "function", template: "material_design" });

    FroalaEditor.RegisterCommand(`fx${uuid}`, {
      title: "Scientific Notation",
      icon: "functionIcon",
      focus: false,
      undo: false,
      callback: function () {
        showMathLiveKeyboard();
      },
    });
  });

  const tempConfig = {
    key: process.env.VUE_APP_FROALA_API_KEY,
    pluginsEnabled: plugins.value,
    imageInsertButtons: ["imageUpload", "imageByURL"],
    imageAllowedTypes: ["jpeg", "jpg", "png", "svg+xml"],
    imageUploadRemoteUrls: true,
    imageUpload: true,
    imageDefaultDisplay: "inline-flex",
    imageDefaultWidth: 150,
    events: {
      "paste.before": function (event) {
        if (props.enableCharacterLimit) {
          const editor = this;
          const currentLength = editor.charCounter ? editor.charCounter.count() : editor.html.get().length;
          const pastedContent = event.clipboardData ? event.clipboardData.getData("text/plain") : ""; // Access pasted plain text
          const maxLength = editor.opts.charCounterMax;

          // Check if pasted content exceeds the limit
          if (currentLength + pastedContent.length > maxLength) {
            const editorContainer = this.$el; // Get the editor container
            editorContainer.css("border", "1px solid red");
            $buefy.toast.open({
              message: `Unable to paste content. Content exceeds character limit of ${maxLength}.`,
              type: "is-danger",
              duration: 4000,
            });
            setTimeout(() => {
              editorContainer.css("border", "");
            }, 4000);
            return false;
          }
        }
      },
      keyup: function () {
        if (props.enableCharacterLimit) {
          const editor = this;
          const currentLength = editor.charCounter ? editor.charCounter.count() : editor.html.get().length;
          const maxLength = editor.opts.charCounterMax;

          const editorContainer = this.$el; // Get the editor container
          if (currentLength >= maxLength) {
            editorContainer.css("border", "1px solid red");
            $buefy.toast.open({
              message: `Character limit of ${maxLength} has been reached.`,
              type: "is-danger",
              duration: 4000,
            });
            setTimeout(() => {
              editorContainer.css("border", "");
            }, 4000);
            return false;
          }
        }
      },
      "image.beforeUpload": checkImageUpload,
      "image.inserted": function () {
        imageCount.count++;
      },
      "image.removed": function () {
        imageCount.count--;
      },
      "image.error": function (error) {
        if (error.code === 5) {
          alert("The image size exceeds the maximum allowed size of 10MB.");
        } else {
          alert("An error occurred while uploading the image.");
        }
      },
    },
    linkAlwaysBlank: true,
    fileUploadRemoteUrls: true,
    requestHeaders: {
      Authorization: `Bearer ${app.$cookies.get("access_token")}`,
    },
    imageMaxSize: 10 * 1024 * 1024,
    linkConvertEmailAddress: true,
    linkInsertButtons: ["linkBack"],
    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "clearFormatting",
        ],
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "lineHeight",
          "indent",
          "outdent",
        ],
      },
      moreRich: {
        buttons: ["insertLink", "insertImage", "insertHR"],
      },
      moreMisc: {
        buttons: ["undo", "redo", `fx${uuid}`, "fullscreen", "getPDF", "selectAll", "help"],
      },
    },
    attribution: false,
    placeholderText: props.placeholder,
    ...(props.enableCharacterLimit && props.maxCharacters
      ? {
          charCounterMax: props.maxCharacters,
        }
      : {}),
  };
</script>

<template>
  <div>
    <b-loading v-model="loading" is-full-page></b-loading>
    <b-field message="Use LaTeX syntax for mathematical expressions." :label="label">
      <froala
        @fxEvent="showMathLiveKeyboard"
        ref="editor"
        :tag="'textarea'"
        :config="tempConfig"
        :value="valueCopy"
        @update:value="valueCopy = $event"></froala>
    </b-field>
    <GenexMathView :content="valueCopy" v-if="!hideMathView" hide-if-no-math @fxEvent="showMathLiveKeyboard" />
    <slot name="footer"></slot>
  </div>
</template>

<style scoped></style>
