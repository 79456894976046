import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
export default class DashboardApplicationByMonth extends GenexSISModel {
  static entity = "dashboard-application-by-month";
  static fields() {
    return {
      campuses: this.attr({}),
      all: this.attr({}),
    };
  }
  static Fetch(query = {}) {
    return this.api().get(`/dashboard/applications-by-month`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(Object.keys(query).length > 0 ? query : {}),
      },
      dataTransformer: ({ data: data }) => {
        if (data.campuses.length > 0 || Object.keys(data.campuses).length > 0) {
          return data;
        }
        return {};
      },
    });
  }
}
