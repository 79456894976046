import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import Stage from "@/models/sis/Stage";
import Phase from "@/models/sis/Phase";
import ApplicationStatus from "@/models/sis/ApplicationStatus";
import ApplicationStage from "@/models/sis/ApplicationStage";
import Campus from "@/models/sis/Campus";
import Programme from "@/models/sis/Programme";
import Package from "@/models/sis/Package";
import Service from "@/models/sis/Service";
import WithdrawalReason from "@/models/sis/WithdrawalReason";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Year from "./Year";

/**
 * An application
 * @property {number} id
 * @property {number} campus_id - The id of the campus this application belongs to
 * @property {number} stage_id - The id of the stage this application belongs to
 * @property {number} phase_id - The id of the phase this application belongs to
 * @property {number} user_id - The id of the user this application belongs to
 * @property {number} year - The year of this application belongs to
 * @property {number} status_id - the id of the status of the application
 * @property {Object} status - the id of the status of the application
 * @property {number} stage_id - the id of the stage of the application
 * @property {Object} stage - the id of the stage of the application
 * @property {number} application_stage_id - the id of the application_stage of the application
 * @property {Object} application_stage - the id of the application_stage of the application
 * @property {string} message
 * @property {string} payment_responsibility
 * @property {string} age_human_readable
 * @property {string} preference_order
 * @property {string} enrolment_date
 * @property {number} home_class_id
 */
export default class Application extends GenexSISModel {
  static entity = "applications";

  static fields() {
    return {
      id: this.attr(null),
      age_human_readable: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      owner_id: this.attr(null),
      owner: this.belongsTo(User, "owner_id"),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      phase_id: this.attr(null),
      phase: this.belongsTo(Phase, "phase_id"),
      campus: this.belongsTo(Campus, "campus_id"),
      campus_id: this.attr(1),
      year: this.attr(null),
      yearModel: this.belongsTo(Year, "year"),
      month: this.attr(null),
      stage_id: this.attr(null),
      stage: this.belongsTo(Stage, "stage_id"),
      application_stage_id: this.attr(null),
      programme_id: this.attr(null),
      programme: this.belongsTo(Programme, "programme_id"),
      application_stage: this.belongsTo(ApplicationStage, "application_stage_id"),
      status_id: this.attr(null),
      victory_status_id: this.attr(null),
      status: this.belongsTo(ApplicationStatus, "status_id"),
      message: this.attr(null),
      priority: this.attr(1),
      payment_responsibility: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      reg_fee_paid_at: this.attr(null),
      activities: this.attr(() => [
        {
          created_at: null,
          note: null,
          user: {},
        },
      ]),
      total_tasks_count: this.attr(null),
      completed_tasks_count: this.attr(null),
      preference_order: this.attr(null),
      future_package_id: this.attr(null),
      future_package: this.belongsTo(Package, "future_package_id"),
      future_service: this.hasManyBy(Service, "future_service_ids"),
      future_service_ids: this.attr([]),
      enrolment_date: this.attr(null),
      home_class_id: this.attr(null),
      withdrawal_reason_note: this.attr(null),
      withdrawal_reason_id: this.attr(null),
      withdrawal_reason: this.belongsTo(WithdrawalReason, "withdrawal_reason_id"),
    };
  }

  static mutators() {
    return {
      user(value) {
        if (value == null) {
          return {
            first_name: "Missing",
            last_name: "User",
          };
        }
        return value;
      },
    };
  }

  /**
   * Returns all applications
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.stage_id]
   * @param {number} [query.phase_id]
   * @param {number} [query.user_id]
   * @param {number} [query.guardian_id]
   * @param {number} [query.owner_id]
   * @param {number} [query.application_stage_id]
   * @param {number} [query.status_id]
   * @param {number} [query.year]
   * @param {number} [query.order_by]
   * @param {number} [query.order_direction]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Application.FetchAll", `/applications`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(application => {
          if (relationships.includes("user")) {
            if (application.attributes.user !== null) {
              Object.assign(application.attributes.user, application.attributes.user.attributes);
            }
          }
          if (relationships.includes("stage")) {
            if (application.attributes.stage !== null) {
              Object.assign(application.attributes.stage, application.attributes.stage.attributes);
            }
          }
          if (relationships.includes("phase")) {
            if (application.attributes.phase !== null) {
              Object.assign(application.attributes.phase, application.attributes.phase.attributes);
            }
          }
          if (relationships.includes("owner")) {
            Object.assign(application.attributes.owner, application.attributes.owner.attributes);
          }
          if (relationships.includes("creator")) {
            Object.assign(application.attributes.creator, application.attributes.creator.attributes);
          }
          if (query.campus_id) {
            application.attributes.campus_id = query.campus_id;
          }
          return { ...application, ...application.attributes };
        });
      },
    });
  }

  /**
   * Returns a application by its id
   * @function
   * @param {number} id The id of the application
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.GenexAxiosGet("Application.FetchById", `/applications/${id}`, {
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          if (data.attributes.user !== null) {
            Object.assign(data.attributes.user, data.attributes.user.attributes);
          }
        }
        if (relationships.includes("creator")) {
          if (data.attributes.creator !== null) {
            Object.assign(data.attributes.creator, data.attributes.creator.attributes);
          }
        }
        if (relationships.includes("owner")) {
          if (data.attributes.owner !== null) {
            Object.assign(data.attributes.owner, data.attributes.owner.attributes);
          }
        }
        if (relationships.includes("stage")) {
          Object.assign(data.attributes.stage, data.attributes.stage.attributes);
        }
        if (relationships.includes("phase")) {
          Object.assign(data.attributes.phase, data.attributes.phase.attributes);
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new application
   * @function
   * @param {Object} application - The application object
   * @param {number} application.campus_id - The id of the campus the application is associated with
   * @param {number} application.stage_id - The id of the stage the application is associated with
   * @param {number} application.application_stage_id - The id of the application stage the application is associated with
   * @param {number} application.phase_id - The id of the phase the application is associated with
   * @param {number} application.user_id - The id of the user the application is associated with
   * @param {number} application.owner_id - The id of the owner the application is associated with
   * @param {number} application.status_id - The status of the application
   * @param {number} application.year - The year of the application
   * @returns {Promise<Response>} - The newly created application
   */

  static Store(application) {
    return this.GenexAxiosPost("Application.Store", `/applications`, application, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing application
   * @function
   * @param {Object} application - The application object
   * @param {number} application.campus_id - The id of the campus the application is associated with
   * @param {number} application.stage_id - The id of the stage the application is associated with
   * @param {number} application.application_stage_id - The id of the application_stage the application is associated with
   * @param {number} application.phase_id - The id of the phase the application is associated with
   * @param {number} application.user_id - The id of the user the application is associated with
   * @param {number} application.owner_id - The id of the owner the application is associated with
   * @param {number} application.status_id - The status of the application
   * @param {number} application.year - The year of the application
   * @param {number} application.id - The id of the application
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created application
   */

  static Update(application, saved = true) {
    return this.GenexAxiosPatch("Application.Update", `/applications/${application.id}`, application, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  static Transfer(application) {
    return this.api().post(`applications/transfer`, application, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      // save: saved,
    });
  }

  static SetStatusLost(application_id) {
    return this.GenexAxiosPost("Application.SetStatusLost", `/applications/${application_id}/lost`, {
      save: false,
    });
  }

  static SetStatusWon(application_id) {
    return this.GenexAxiosPost("Application.SetStatusWon", `/applications/${application_id}/won`, {
      save: false,
    });
  }

  static SetStatusNone(application_id) {
    return this.GenexAxiosPost("Application.SetStatusNone", `/applications/${application_id}/new`, {
      save: false,
    });
  }

  /**
   * Create an enrolment from this application. Only for general course applications
   * @function
   * @param {number} application_id - The id of the application
   * @param { string } date - enrolment date of the application
   */

  static Enrol(application_id, date) {
    return this.GenexAxiosPost("Application.Enrol", `/applications/${application_id}/enrol`, date);
  }

  /**
   * Download an application
   * @function
   * @param {number} application_id - The id of the application
   */

  static Download(application_id) {
    return this.GenexAxiosGet("Application.Download", `/applications/${application_id}/download`, {
      responseType: "arraybuffer",
      save: false,
    });
  }

  static BulkDownload(application_ids) {
    return this.GenexAxiosPost(
      "Application.BulkDownload",
      `/applications/bulk-download`,
      {
        application_ids: application_ids,
      },
      {
        responseType: "arraybuffer",
        save: false,
      },
    );
  }

  /**
   * Delete an existing application
   * @function
   * @param {number} application_id - The id of the application
   * @returns {Promise<Response>} - The newly created application
   */

  static Delete(application_id) {
    return this.GenexAxiosDelete("Application.Delete", `/applications/${application_id}`, {
      delete: application_id,
    });
  }
}
