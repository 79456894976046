export const widgetsModule = {
  namespaced: true,
  state: {
    loaded: false,
    widgets: [
      {
        id: 1,
        name: "Enrolment Status Changes",
      },
      {
        id: 2,
        name: "Staff Totals",
      },
      {
        id: 3,
        name: "Stage Student Ratio",
      },
      {
        id: 4,
        name: "Educator Student Ratio",
      },
      {
        id: 5,
        name: "Student Services",
      },
      {
        id: 6,
        name: "Application To Enrolment",
      },
      {
        id: 7,
        name: "Applications By Status",
      },
      {
        id: 8,
        name: "Applications By Stage",
      },
      {
        id: 9,
        name: "Application Stats",
      },
      {
        id: 10,
        name: "Engagements",
      },
      {
        id: 11,
        name: "Staff Retention",
      },
      {
        id: 12,
        name: "Pending Reviews",
      },
      {
        id: 13,
        name: "Monthly application by Campus",
      },
      {
        id: 14,
        name: "Enrolment Withdrawal Stats",
      },
      {
        id: 15,
        name: "Course Progress",
      },
      {
        id: 16,
        name: "Checkpoints",
      },
      {
        id: 17,
        name: "Lesson Reflections",
      },
    ],
  },
};
