import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import EventType from "@/models/sis/EventType";
import Media from "@/models/sis/Media";
import Store from "@/store";
import Department from "@/models/sis/Department";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";
import CalendarEventAudience from "@/models/sis/CalendarEventAudience";

/**
 * An event
 * @property {number} id
 * @property {number} campus_id - The id of the campus this announcement belongs to
 * @property {number} event_type_id - The id of the event_type this event has
 * @property {string} start_date_time
 * @property {string} end_date_time
 * @property {string} name
 * @property {string} description
 * @property {string} created_at
 * @property {boolean} is_internal
 * @property {number} user_id - The id of the user this announcement belongs to
 */
export default class CalendarEvent extends GenexSISModel {
  static entity = "calendar_events";
  const;

  static fields() {
    return {
      id: this.attr(null),
      start_date_time: this.attr(null),
      end_date_time: this.attr(null),
      campuses: this.hasManyBy(Campus, "campus_ids"),
      campus_ids: this.attr(() => []),
      campus_id: this.attr(null),
      created_at: this.attr(null),
      name: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      event_type: this.belongsTo(EventType, "event_type_id"),
      event_type_id: this.attr(null),
      is_internal: this.attr(false),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      all_day: this.attr(0),
      allDay: this.attr(false),
      start: this.attr(null),
      end: this.attr(null),
      color: this.attr("#26657B"),
      departments: this.belongsTo(Department, "filters.department_ids"),
      filters: this.attr({
        department_ids: [],
      }),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      audience_ids: this.attr(() => []),
      audience: this.hasManyBy(CalendarEventAudience, "audience_ids"),
      is_lms: this.attr(false),
      events_event_audiences: this.attr([]),
    };
  }

  static remapEvent(event) {
    event.attributes.title = event.attributes.name;
    event.attributes.start = event.attributes.start_date_time;
    event.attributes.end = event.attributes.end_date_time;
    event.attributes.allDay = event.attributes.all_day === 1;
    event.attributes.color = Store.state.calendar.colours["event_" + event.attributes.event_type_id + "_color"];
    return event;
  }

  /**
   * Returns all events
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?string} [query.eventStart]
   * @param {?string} [query.eventEnd]
   * @param {?number} [query.campus]
   * @param {?boolean} [query.internal]
   * @param {?string} [query.name]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/events`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(
          data.map(event => {
            return this.remapEvent(event);
          }),
        );
      },
    });
  }

  /**
   * Returns an event by its id
   * @function
   * @param {number} id The id of the event
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/events/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(this.remapEvent(data));
      },
    });
  }

  /**
   * Store a new event
   * @function
   * @param {Object} event - The event object
   * @returns {Promise<Response>} - The newly created event
   */

  static Store(event) {
    return this.api().post(`/events?with[]=campuses`, event, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(this.remapEvent(data));
      },
    });
  }

  /**
   * Update an existing event
   * @function
   * @param {Object} event - The event object
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created application
   */

  static Update(event, saved = true) {
    return this.api().put(`/events/${event.id}?with[]=campuses`, event, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(this.remapEvent(data));
      },
      save: saved,
    });
  }

  /**
   * Delete an existing event
   * @function
   * @param {number} event_id - The id of the event
   * @returns {Promise<Response>} - The status of the delete
   */

  static Delete(event_id) {
    return this.api().delete(`/events/${event_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: event_id,
    });
  }
}
