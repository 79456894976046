import Announcement from "@/models/sis/Announcement";
import AnnouncementModel from "@/models/sis/AnnouncementModel";
import Application from "@/models/sis/Application";
import ApplicationStage from "@/models/sis/ApplicationStage";
import ApplicationStatus from "@/models/sis/ApplicationStatus";
import ApplicationInProgress from "@/models/sis/ApplicationInProgress";
import Assessment from "@/models/sis/Assessment";
import AssessmentGradeThreshold from "@/models/sis/AssessmentGradeThreshold";
import Attendance from "@/models/sis/Attendance";
import AttendanceDashboard from "@/models/sis/AttendanceDashboard";
import AttendanceRecord from "@/models/sis/AttendanceRecord";
import CalendarEvent from "@/models/sis/CalendarEvent";
import Campus from "@/models/sis/Campus";
import CampusPublic from "@/models/sis/CampusPublic";
import CampusUser from "@/models/sis/CampusUser";
import ClassGroup from "@/models/sis/ClassGroup";
import CourseScope from "@/models/sis/CourseScope";
import CovidAssessment from "@/models/sis/CovidAssessment";
import Currency from "@/models/sis/Currency";
import DashboardAcademicLessonPlanEngagement from "@/models/sis/DashboardAcademicLessonPlanEngagement";
import DashboardAdmissions from "@/models/sis/DashboardAdmissions";
import DashboardAdmissionsNewMonth from "@/models/sis/DashboardAdmissionsNewMonth";
import DashboardAdmissionsWaitingStage from "@/models/sis/DashboardAdmissionsWaitingStage";
import DashboardAdmissionsWithdrawn from "@/models/sis/DashboardAdmissionsWithdrawn";
import DashboardApplicationToEnrolment from "@/models/sis/DashboardApplicationToEnrolment";
import DashboardApplicationToEnrolmentTotals from "@/models/sis/DashboardApplicationToEnrolmentTotals";
import DashboardApplicationsByStage from "@/models/sis/DashboardApplicationsByStage";
import DashboardApplicationsByStatus from "@/models/sis/DashboardApplicationsByStatus";
import DashboardAttendancesState from "@/models/sis/DashboardAttendancesState";
import DashboardAttendancesType from "@/models/sis/DashboardAttendancesType";
import DashboardEducatorPhase from "@/models/sis/DashboardEducatorPhase";
import DashboardEducatorStage from "@/models/sis/DashboardEducatorStage";
import DashboardEnrolments from "@/models/sis/DashboardEnrolments";
import DashboardApplicationByMonth from "@/models/sis/DahsboardApplicationByMonth";
import DashboardEnrolmentsStudentServices from "@/models/sis/DashboardEnrolmentsStudentServices";
import DashboardRatio from "@/models/sis/DashboardRatio";
import DashboardStaffTotals from "@/models/sis/DashboardStaffTotal";
import DateEnrolment from "@/models/sis/DateEnrolment";
import Department from "@/models/sis/Department";
import Document from "@/models/sis/Document";
import Enrolment from "@/models/sis/Enrolment";
import EnrolmentStatus from "@/models/sis/EnrolmentStatus";
import EventType from "@/models/sis/EventType";
import Extramural from "@/models/sis/Extramural";
import Folder from "@/models/sis/Folder";
import LMSForum from "@/models/lms/LMSForum";
import LMSUser from "@/models/lms/LMSUser";
import LMSForumTopic from "@/models/lms/LMSForumTopic";
import LMSForumPost from "@/models/lms/LMSForumPost";
import LMSForumComment from "@/models/lms/LMSForumComment";
import LMSForumSubComment from "@/models/lms/LMSForumSubComment";
import LMSLesson from "@/models/lms/LMSLesson";
import LMSLessonReflections from "@/models/lms/LMSLessonReflections";
import LMSCourseProgress from "@/models/lms/LMSCourseProgress";
import LMSCourseModuleProgress from "@/models/lms/LMSCourseModuleProgress";
import LMSCourses from "@/models/lms/LMSCourses";
import Grade from "@/models/sis/Grade";
import HelpdeskUser from "@/models/sis/HelpdeskUser";
import HomeClass from "@/models/sis/HomeClass";
import ILP from "@/models/sis/ILP";
import ILPObjective from "@/models/sis/ILPObjective";
import ILPStudentProgress from "@/models/sis/ILPStudentProgress";
import ILPTopic from "@/models/sis/ILPTopic";
import ILPUnit from "@/models/sis/ILPUnit";
import Incident from "@/models/sis/Incident";
import TaskSheet from "@/models/TaskSheet";
import Invite from "@/models/sis/Invite";
import InviteUser from "@/models/sis/InviteUser";
import LearnerReflection from "@/models/sis/LearnerReflection";
import LearningStream from "@/models/sis/LearningStream";
import LeaveRequest from "@/models/sis/LeaveRequest";
import LeaveType from "@/models/sis/LeaveType";
import Lesson from "@/models/sis/Lesson";
import LessonPlan from "@/models/sis/LessonPlan";
import Level from "@/models/sis/Level";
import Lookup from "@/models/sis/Lookup";
import MarksheetUser from "@/models/sis/MarksheetUser";
import Media from "@/models/sis/Media";
import Meeting from "@/models/sis/Meeting";
import Note from "@/models/sis/Note";
import Objective from "@/models/sis/Objective";
import Package from "@/models/sis/Package";
import Permission from "@/models/sis/Permission";
import PermissionRole from "@/models/sis/PermissionRole";
import Phase from "@/models/sis/Phase";
import Profile from "@/models/sis/Profile";
import Programme from "@/models/sis/Programme";
import ProgressType from "@/models/sis/ProgressType";
import Release from "@/models/sis/Release";
import Report from "@/models/sis/Report";
import ReportExtramural from "@/models/sis/ReportExtramural";
import ReportExtramuralComment from "@/models/sis/ReportExtramualComment";
import ReportFinalGroup from "@/models/sis/ReportFinalGroup";
import ReportFinalGroupOption from "@/models/sis/ReportFinalGroupOption";
import ReportMarkGroup from "@/models/sis/ReportMarkGroup";
import ReportMarkGroupAssessment from "@/models/sis/ReportMarkGroupAssessment";
import ReportSubject from "@/models/sis/ReportSubject";
import ReportSubjectAssessmentGrades from "@/models/sis/ReportSubjectAssessmentGrades";
import ReportSubjectComment from "@/models/sis/ReportSubjectComment";
import ReportSubjectGradeThreshold from "@/models/sis/ReportSubjectGradeThreshold";
import ReportSubjectGradeTotals from "@/models/sis/ReportSubjectGradeTotals";
import ReportTemplate from "@/models/sis/ReportTemplate";
import ReportTemplateLayout from "@/models/sis/ReportTemplateLayout";
import ReportUserComment from "@/models/sis/ReportUserComment";
import Role from "@/models/sis/Role";
import RoleUser from "@/models/sis/RoleUser";
import Service from "@/models/sis/Service";
import Setting from "@/models/sis/Setting";
import SportsHouse from "@/models/sis/SportsHouse";
import StaffProfile from "@/models/sis/StaffProfile";
import Stage from "@/models/sis/Stage";
import StudentAttendanceRecord from "@/models/sis/StudentAttendanceRecord";
import Subject from "@/models/sis/Subject";
import SubjectConfig from "@/models/sis/SubjectConfig";
import SubjectUser from "@/models/sis/SubjectUser";
import SupportTicket from "@/models/sis/SupportTicket";
import Task from "@/models/sis/Task";
import TaskType from "@/models/sis/TaskType";
import TeacherReflection from "@/models/sis/TeacherReflection";
import Tenant from "@/models/sis/Tenant";
import Term from "@/models/sis/Term";
import Topic from "@/models/sis/Topic";
import Unit from "@/models/sis/Unit";
import UnitObjective from "@/models/sis/UnitObjective";
import User from "@/models/sis/User";
import UserImport from "@/models/sis/UserImport";
import UserNotification from "@/models/sis/UserNotification";
import UserPermission from "@/models/sis/UserPermission";
import UserRecord from "@/models/sis/UserRecord";
import UserRecordType from "@/models/sis/UserRecordType";
import DashboardHRDashboardStaffRetention from "@/models/sis/DashboardHRDashboardStaffRetention";
import UserReport from "@/models/sis/UserReport";
import Video from "@/models/sis/Video";
import VideoCategory from "@/models/sis/VideoCategory";
import VuexORM from "@vuex-orm/core";
import AlertAudience from "@/models/sis/AlertAudience";
import WithdrawalReason from "@/models/sis/WithdrawalReason";
import AssessmentSubmission from "@/models/sis/AssessmentSubmission";
import AssessmentFeedback from "@/models/sis/AssessmentFeedback";
import JournalEntry from "@/models/sis/JournalEntry";
import JournalFeedback from "@/models/sis/JournalFeedback";
import Activity from "@/models/sis/Activity";
import Alert from "@/models/sis/Alert";
import LMSCheckpointsProgress from "@/models/lms/LMSCheckpointsProgress";
import FileType from "@/models/sis/FileType";
import { LMSAssessment } from "@/models/lms-central/LMSAssessment";
import { LMSAssessmentUser } from "@/models/lms/LMSAssessmentUser";
import LMSModule from "@/models/lms/LMSModule";
import { LMSAssessmentQuestion } from "@/models/lms-central/LMSAssessmentQuestion";
import CentralPasswordReset from "@/models/sis-central/CentralPasswordReset";
import UserUnit from "@/models/sis/UserUnit";
import MontessoriObservation from "@/models/sis/MontessoriObservation";
import ObjectiveProgress from "@/models/sis/ObjectiveProgress";
import ObjectiveProgressLog from "@/models/sis/ObjectiveProgressLog";
import UserTaskSheet from "@/models/sis/UserTaskSheet";
import CourseScopeTaskSheet from "@/models/sis/CourseScopeTaskSheet";
import { ProgressSubject } from "@/models/sis/ProgressSubject";
import { ProgressPhase } from "@/models/sis/ProgressPhase";
import { ProgressYear } from "@/models/sis/ProgressYear";
import LessonPlanAi from "@/models/sis/LessonPlanAi";
import Year from "@/models/sis/Year";
import EnrolmentWithdrawn from "@/models/sis/DashboardEnrolmentWithdrawn";
import Widget from "@/models/sis/Widget";

export const database = new VuexORM.Database();

database.register(Alert);
database.register(AlertAudience);
database.register(Announcement);
database.register(AnnouncementModel);
database.register(Application);
database.register(ApplicationInProgress);
database.register(ApplicationStage);
database.register(ApplicationStatus);
database.register(Assessment);
database.register(AssessmentGradeThreshold);
database.register(Attendance);
database.register(AttendanceDashboard);
database.register(AttendanceRecord);
database.register(CalendarEvent);
database.register(Campus);
database.register(CampusPublic);
database.register(CampusUser);
database.register(ClassGroup);
database.register(Currency);
database.register(CourseScope);
database.register(CovidAssessment);
database.register(DashboardAcademicLessonPlanEngagement);
database.register(DashboardAdmissions);
database.register(DashboardAdmissionsNewMonth);
database.register(DashboardAdmissionsWaitingStage);
database.register(DashboardAdmissionsWithdrawn);
database.register(DashboardApplicationToEnrolment);
database.register(DashboardApplicationToEnrolmentTotals);
database.register(DashboardApplicationsByStage);
database.register(DashboardApplicationsByStatus);
database.register(DashboardAttendancesState);
database.register(DashboardAttendancesType);
database.register(DashboardEducatorPhase);
database.register(DashboardEducatorStage);
database.register(DashboardEnrolments);
database.register(DashboardApplicationByMonth);
database.register(DashboardEnrolmentsStudentServices);
database.register(DashboardHRDashboardStaffRetention);
database.register(DashboardRatio);
database.register(DashboardStaffTotals);
database.register(DateEnrolment);
database.register(Department);
database.register(Document);
database.register(Enrolment);
database.register(EnrolmentStatus);
database.register(EventType);
database.register(Extramural);
database.register(Folder);
database.register(LMSUser);
database.register(LMSForum);
database.register(LMSForumTopic);
database.register(LMSForumPost);
database.register(LMSForumComment);
database.register(LMSForumSubComment);
database.register(LMSLessonReflections);
database.register(LMSLesson);
database.register(LMSCourseProgress);
database.register(LMSCourseModuleProgress);
database.register(LMSCourses);
database.register(Grade);
database.register(HelpdeskUser);
database.register(HomeClass);
database.register(ILP);
database.register(ILPObjective);
database.register(ILPStudentProgress);
database.register(ILPTopic);
database.register(ILPUnit);
database.register(Incident);
database.register(Invite);
database.register(InviteUser);
database.register(LearnerReflection);
database.register(LearningStream);
database.register(LeaveRequest);
database.register(LeaveType);
database.register(Lesson);
database.register(LessonPlan);
database.register(Level);
database.register(Lookup);
database.register(MarksheetUser);
database.register(Media);
database.register(Meeting);
database.register(Note);
database.register(Objective);
database.register(Package);
database.register(Permission);
database.register(PermissionRole);
database.register(Phase);
database.register(Profile);
database.register(Programme);
database.register(ProgressType);
database.register(Release);
database.register(Report);
database.register(ReportExtramural);
database.register(ReportExtramuralComment);
database.register(ReportFinalGroup);
database.register(ReportFinalGroupOption);
database.register(ReportMarkGroup);
database.register(ReportMarkGroupAssessment);
database.register(ReportSubject);
database.register(ReportSubjectAssessmentGrades);
database.register(ReportSubjectComment);
database.register(ReportSubjectGradeThreshold);
database.register(ReportSubjectGradeTotals);
database.register(ReportTemplate);
database.register(ReportTemplateLayout);
database.register(ReportUserComment);
database.register(Role);
database.register(RoleUser);
database.register(Service);
database.register(Setting);
database.register(SportsHouse);
database.register(StaffProfile);
database.register(Stage);
database.register(StudentAttendanceRecord);
database.register(Subject);
database.register(SubjectConfig);
database.register(SubjectUser);
database.register(SupportTicket);
database.register(Task);
database.register(TaskSheet);
database.register(TaskType);
database.register(TeacherReflection);
database.register(Tenant);
database.register(Topic);
database.register(Unit);
database.register(UnitObjective);
database.register(User);
database.register(UserImport);
database.register(UserNotification);
database.register(UserPermission);
database.register(UserRecord);
database.register(UserRecordType);
database.register(UserReport);
database.register(Video);
database.register(VideoCategory);
database.register(WithdrawalReason);
database.register(AssessmentSubmission);
database.register(AssessmentFeedback);
database.register(JournalEntry);
database.register(JournalFeedback);
database.register(Term);
database.register(Activity);
database.register(LMSCheckpointsProgress);
database.register(LMSAssessment);
database.register(LMSAssessmentUser);
database.register(LMSAssessmentQuestion);
database.register(LMSModule);
database.register(FileType);
database.register(CentralPasswordReset);
database.register(UserUnit);
database.register(MontessoriObservation);
database.register(ObjectiveProgress);
database.register(ObjectiveProgressLog);
database.register(UserTaskSheet);
database.register(CourseScopeTaskSheet);
database.register(ProgressSubject);
database.register(ProgressPhase);
database.register(ProgressYear);
database.register(LessonPlanAi);
database.register(Year);
database.register(EnrolmentWithdrawn);
database.register(Widget);
