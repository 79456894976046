import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class EnrolmentWithdrawn extends GenexSISModel {
  static entity = "enrolments_withdrawn";
  static fields() {
    return {
      campus_name: this.attr(null),
      campus_id: this.attr(null),
      enrolments: this.attr([]),
    };
  }
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationship = []) {
    return this.api().get(`/dashboard/enrolments/withdrawn`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationship,
        },
        ...(Object.keys(query).length > 0 ? query : {}),
      },
      dataTransformer: ({ data: { withdrawn_enrolments } }) => {
        return withdrawn_enrolments;
      },
    });
  }
}
